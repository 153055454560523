import * as React from "react";
import { useFormspark } from "@formspark/use-formspark";
import Layout from "../components/Layout";

const AdmissionForm = () => {
  const [submit, submitting] = useFormspark({
    formId: "P7VJ3dmBA",
  });

  const [full_name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [school, setSchool] = React.useState("");
  const [class_name, setClass] = React.useState("");
  const [program, setProgram] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Layout>
      <section className="contact section-padding">
        <div className="container">
          <div className="lg-bold-head text-center gray mb-80">
            <h1 className="sub-font">Admission</h1>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="sec-head mb-40">
                <h6 className="mb-15 fw-400">
                  // Enroll at Apex Learning Institute
                </h6>
                <h3 className="fw-700">
                  Fill out the form below to get started for your Admission
                </h3>
              </div>
              <form
                id="contact-form"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await submit({
                    full_name,
                    number,
                    address,
                    school,
                    class_name,
                    program,
                  }).then(() => {
                    setName("");
                    setNumber("");
                    setAddress("");
                    setSchool("");
                    setClass("");
                    setProgram("");

                    setIsLoading(true);
                  });
                }}
              >
                <div className="messages"></div>

                <div className="controls row">
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label for="">Your Name</label>
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        placeholder="Enter Your Name"
                        required="required"
                        data-error="Name is required."
                        value={full_name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label for="">Your Number</label>
                      <input
                        id="number"
                        type="string"
                        name="number"
                        placeholder="Enter Your Number"
                        required="required"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-30">
                    <div className="form-group">
                      <label for="">Address</label>
                      <input
                        id="address"
                        type="text"
                        name="address"
                        placeholder="Enter Your Address"
                        required="required"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label for="">Current School</label>
                      <input
                        id="school"
                        type="string"
                        name="school"
                        placeholder="Enter Your Current School"
                        required="required"
                        value={school}
                        onChange={(e) => setSchool(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label for="">Class</label>
                      <input
                        id="class"
                        type="string"
                        name="class"
                        placeholder="Enter Your Class"
                        required="required"
                        value={class_name}
                        onChange={(e) => setClass(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label for="">What program are you interested in?</label>
                      <input
                        id="program"
                        type="string"
                        name="program"
                        placeholder="Enter Program"
                        required="required"
                        value={program}
                        onChange={(e) => setProgram(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-info mt-15">
                  <p className="fz-14">
                    * Call us Mon-Sat, 10AM-7PM or fill out the form below to
                    receive a free.
                  </p>
                </div>
                <div className="mt-50">
                  <button
                    type="submit"
                    className="d-flex align-items-center"
                    disabled={submitting}
                  >
                    <span className="text">Submit Admission Form</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                    >
                      <path
                        d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                        stroke="#fff"
                        strokeWidth="2"
                      ></path>
                    </svg>
                  </button>
                </div>

                <p style={{ color: "green", fontSize: "20px", marginTop: "1rem" }}>
                  {isLoading ? (
                    <span className="text-success">Form Submitted!</span>
                  ) : (
                    ""
                  )}
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AdmissionForm;
